<template>
  <v-app>
  <Header></Header>
      <v-container fluid style="padding: 10vh;margin-top: 20vh">
      <v-row>
        <v-col cols="12" md="5"  class="hero" >
          <h2 class="white--text text-left pa-8 pb-0">Join Us</h2>
          <p class="white--text text-left pa-8 pb-0">To begin this journey ,tell us what type of account
            you would be opining.
          </p>
          <br>
            <v-row style="background-color: white;margin-left: 7%;margin-right: 7%">
              <v-col cols="12" md="3">
                <v-img :src="img" max-width="50" max-height="50">
                </v-img>
              </v-col>
              <v-col >
                <router-link to="/individual">
                <h2 class="black--text text-left">Individual</h2>
                <p class="black--text text-left font-weight-thin">personal account to manage all you <v-icon>mdi-arrow-right</v-icon> </p>
                </router-link>
              </v-col>
            </v-row>
          <br>
          <br>
          <v-row style="background-color: white;margin-left: 7%;margin-right: 7%">
            <v-col cols="12" md="3">
              <v-img :src="img2" max-width="50" max-height="50">
              </v-img>
            </v-col>
            <v-col >
              <router-link to="/business">
              <h2 class="black--text text-left">Business</h2>
              <p class="black--text text-left font-weight-thin">Own or belong to company ,this is </p>
              </router-link>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="7">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-card-title>Profession Post</v-card-title>
                <v-card-text>For the Purpose of industry regulation ,your detail
                  are required .</v-card-text>
                <v-text-field
                    label="your fullName *"
                    outlined
                    dense
                ></v-text-field>
                <v-text-field
                    label="Profession *"

                    outlined
                    dense
                ></v-text-field>
                <v-text-field
                    label="Phone number *"
                    type="number"
                    outlined
                    dense
                ></v-text-field>
                <v-text-field
                    label="Whatsapp number *"
                    type="number"
                    outlined
                    dense
                ></v-text-field>
                <v-text-field
                    label="Email address *"
                    type="email"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <br>
                <br>
                <br>
                <br>
                <br>
                <v-file-input
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Pick a profile photo"
                    dense
                    prepend-icon="mdi-camera"
                    outlined
                    label="Upload your profile photo"
                ></v-file-input>
                <br>
                <v-card-subtitle class="justify-center">Select the option</v-card-subtitle>
                <v-row>
                  <v-col cols="12" md="6">
                    <label style="color: blue;font-size: 12px;padding: 8px">only for 30 days</label>
<v-btn class="neumorphic" rounded> KD:25.000 <v-icon color="blue">mdi-check-underline-circle-outline</v-icon></v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <label style="color: blue;font-size: 12px;padding: 8px">only for 60 days</label>
                    <v-btn rounded class="neumorphic"> KD:25.000 <v-icon color="blue">mdi-check-circle</v-icon></v-btn>
                  </v-col>

                </v-row>
                <br>
                <br>
                <br>
                <div align="center"><v-btn  class="neumorphic" color="blue" width="220">Submit</v-btn></div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      </v-container>

  <LandingPageFooter></LandingPageFooter>
  </v-app>
</template>

<script>
import Header from "@/components/secondlayouts/Header";
import LandingPageFooter from "@/components/layouts/LandingPageFooter";
export default {
  components:{
    Header,
    LandingPageFooter
  },
  data: () => ({
    img: require('@/assets/images/Individual.svg'),
    img2: require('@/assets/images/Business.svg'),
    staticImg:require('@/assets/images/jois us background.jpg'),
    interiorImg:require('@/assets/images/Axles Agents  Interior Designer.svg')
  }),
}
</script>

<style scoped>
.marg{
  margin: 16px;
}
.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, 8);
  padding: 1.4rem;
}
.hero{
  background-image: url('../assets/images/join us background 2.jpg');
  background-size: cover;
  height: 100vh;
  border-radius: 40px;
}
a{
  text-decoration: none;
}
</style>