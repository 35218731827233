<template>
  <v-app-bar app
             light
             height="100"
  style="background-color: white">
    <v-btn style="padding: 8px" class="neumorphic"><v-icon >{{langLogo}}</v-icon></v-btn>
    <v-spacer></v-spacer>

      <v-tab link to="/">Home</v-tab>
      <v-tab link to="/login">Login</v-tab>
      <v-tab link to="/about">About Us</v-tab>
    <div style="width: 10%;"></div>
    <v-img :src="logo" max-height="60" max-width="220" light></v-img>
  </v-app-bar>

</template>

<script>
export default {
name: "Header",
  data: () => ({
    langLogo:'mdi-arrow-left',
    logo: require('@/assets/images/logo.svg'),
  }),
}
</script>

<style scoped>
a{
  text-transform: none;
}
.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, 8);
  font-size: 8rem;
  padding: 1.4rem;
}

</style>