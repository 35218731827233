<template>
  <v-app>
    <Header></Header>
    <v-container fluid style="padding: 10vh;margin-top: 20vh;margin-bottom: 20vh" class="hero">
      <v-row>
        <v-col cols="12" md="4" sm="6">
          <v-img :src="img" style="border-radius: 40px"></v-img>
        </v-col>
        <v-col cols="12" md="2" sm="2"></v-col>
        <v-col cols="12" md="4" sm="6">
          <br>
          <br>
          <v-text-field
              label="your *"
              background-color="white"
              type="text"
              outlined
              dense
          ></v-text-field>
          <v-text-field
              label="Mobile *"
              background-color="white"
              type="number"
              outlined
              dense
          ></v-text-field>
          <v-text-field
              label="Email *"
              background-color="white"
              type="email"
              outlined
              dense
          ></v-text-field>
          <v-text-field
              label="Password *"
              background-color="white"
              type="password"
              outlined
              dense
          ></v-text-field>
          <v-checkbox v-model="checkbox" color="primary">
            <template v-slot:label>
              <div style="color: white">
                I agree to terms & conditions
              </div>
            </template>
          </v-checkbox>
          <div align="center">
            <v-btn type="submit" width="150"  color="#2d2d2d"><span style="color: #c69601">Register Account</span> </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="2" sm="2"></v-col>
      </v-row>

    </v-container>

    <LandingPageFooter></LandingPageFooter>
  </v-app>
</template>

<script>
import Header from "@/components/secondlayouts/Header";
import LandingPageFooter from "@/components/layouts/LandingPageFooter";
export default {
  components:{
    Header,
    LandingPageFooter
  },
  data: () => ({
    checkbox: false,
    img: require('@/assets/images/login image.jpg'),
    img2: require('@/assets/images/Business.svg'),
    staticImg:require('@/assets/images/jois us background.jpg'),
    interiorImg:require('@/assets/images/Axles Agents  Interior Designer.svg')
  }),
}
</script>

<style scoped>
.marg{
  margin: 16px;
}
.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, 8);
  padding: 1.4rem;
}
.hero{
  background-image: url('../assets/images/geo matric background shapes.svg');
  background-size: cover;
  background-color: #2D2D2D;
}
a{
  text-decoration: none;
  font-size: 12px;
  color: white;
}
</style>