var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('LandingPageBanner'),_c('Navbar'),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"ml-0 mr-0 pl-0 pr-0",attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"border-radius":"20px"},attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto neumorphic variation1",class:{ 'on-hover': hover },staticStyle:{"border-radius":"20px"},attrs:{"elevation":hover ? 12 : 2,"max-width":"335","max-height":"445"}},[_c('div',{staticStyle:{"height":"4px"}}),_c('v-img',{staticClass:" img ma-2 img pt-4",attrs:{"src":"https://cdn.vuetifyjs.com/images/cards/sunshine.jpg","height":"350px","width":"320","aspect-ratio":"1"}}),_c('v-card-title',[_vm._v(" Top western road trips ")]),_c('v-card-subtitle',[_vm._v(" 1,000 miles of wonder ")])],1)]}}])})],1),_c('v-col',{staticStyle:{"border-radius":"20px"},attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto neumorphic variation1",class:{ 'on-hover': hover },staticStyle:{"border-radius":"20px"},attrs:{"elevation":hover ? 12 : 2,"max-width":"335","max-height":"445"}},[_c('div',{staticStyle:{"height":"4px"}}),_c('v-img',{staticClass:" img ma-2 img pt-4",attrs:{"src":"https://cdn.vuetifyjs.com/images/cards/sunshine.jpg","height":"350px","width":"320","aspect-ratio":"1"}}),_c('v-card-title',[_vm._v(" Top western road trips ")]),_c('v-card-subtitle',[_vm._v(" 1,000 miles of wonder ")])],1)]}}])})],1),_c('v-col',{staticStyle:{"border-radius":"20px"},attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto neumorphic variation1",class:{ 'on-hover': hover },staticStyle:{"border-radius":"20px"},attrs:{"elevation":hover ? 12 : 2,"max-width":"335","max-height":"445"}},[_c('div',{staticStyle:{"height":"4px"}}),_c('v-img',{staticClass:" img ma-2 img pt-4",attrs:{"src":"https://cdn.vuetifyjs.com/images/cards/sunshine.jpg","height":"350px","width":"320","aspect-ratio":"1"}}),_c('v-card-title',[_vm._v(" Top western road trips ")]),_c('v-card-subtitle',[_vm._v(" 1,000 miles of wonder ")])],1)]}}])})],1)],1),_c('br'),_c('br'),_c('br'),_c('v-row',{staticClass:"ml-0 mr-0 pl-0 pr-0",attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"border-radius":"20px"},attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto neumorphic variation1",class:{ 'on-hover': hover },staticStyle:{"border-radius":"20px"},attrs:{"elevation":hover ? 12 : 2,"max-width":"335","max-height":"445"}},[_c('div',{staticStyle:{"height":"4px"}}),_c('v-img',{staticClass:" img ma-2 img pt-4",attrs:{"src":"https://cdn.vuetifyjs.com/images/cards/sunshine.jpg","height":"350px","width":"320","aspect-ratio":"1"}}),_c('v-card-title',[_vm._v(" Top western road trips ")]),_c('v-card-subtitle',[_vm._v(" 1,000 miles of wonder ")])],1)]}}])})],1),_c('v-col',{staticStyle:{"border-radius":"20px"},attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto neumorphic variation1",class:{ 'on-hover': hover },staticStyle:{"border-radius":"20px"},attrs:{"elevation":hover ? 12 : 2,"max-width":"335","max-height":"445"}},[_c('div',{staticStyle:{"height":"4px"}}),_c('v-img',{staticClass:" img ma-2 img pt-4",attrs:{"src":"https://cdn.vuetifyjs.com/images/cards/sunshine.jpg","height":"350px","width":"320","aspect-ratio":"1"}}),_c('v-card-title',[_vm._v(" Top western road trips ")]),_c('v-card-subtitle',[_vm._v(" 1,000 miles of wonder ")])],1)]}}])})],1),_c('v-col',{staticStyle:{"border-radius":"20px"},attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto neumorphic variation1",class:{ 'on-hover': hover },staticStyle:{"border-radius":"20px"},attrs:{"elevation":hover ? 12 : 2,"max-width":"335","max-height":"445"}},[_c('div',{staticStyle:{"height":"4px"}}),_c('v-img',{staticClass:" img ma-2 img pt-4",attrs:{"src":"https://cdn.vuetifyjs.com/images/cards/sunshine.jpg","height":"350px","width":"320","aspect-ratio":"1"}}),_c('v-card-title',[_vm._v(" Top western road trips ")]),_c('v-card-subtitle',[_vm._v(" 1,000 miles of wonder ")])],1)]}}])})],1)],1),_c('br')],1),_c('landing-page-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }