<template>
  <v-app>
    <LandingPageBanner></LandingPageBanner>
    <Navbar></Navbar>
    <v-container fluid>
      <v-row justify="center" class="ml-0 mr-0 pl-0 pr-0">
        <v-col style="border-radius: 20px" cols="12" md="4">
          <v-hover v-slot="{ hover }">
            <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto neumorphic variation1"
                max-width="335"
                max-height="445"
                style="border-radius: 20px"
            ><div style="height: 4px"></div>
              <v-img

                  class=" img ma-2 img pt-4"

                  src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                  height="350px"
                  width="320"
                  aspect-ratio="1"
              ></v-img>

              <v-card-title>
                Top western road trips
              </v-card-title>

              <v-card-subtitle>
                1,000 miles of wonder
              </v-card-subtitle>
            </v-card>
          </v-hover>
        </v-col>
        <v-col style="border-radius: 20px" cols="12" md="4">
          <v-hover v-slot="{ hover }">
            <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto neumorphic variation1"
                max-width="335"
                max-height="445"
                style="border-radius: 20px"
            ><div style="height: 4px"></div>
              <v-img

                  class=" img ma-2 img pt-4"

                  src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                  height="350px"
                  width="320"
                  aspect-ratio="1"
              ></v-img>

              <v-card-title>
                Top western road trips
              </v-card-title>

              <v-card-subtitle>
                1,000 miles of wonder
              </v-card-subtitle>
            </v-card>
          </v-hover>
        </v-col>
        <v-col style="border-radius: 20px" cols="12" md="4">
          <v-hover v-slot="{ hover }">
            <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto neumorphic variation1"
                max-width="335"
                max-height="445"
                style="border-radius: 20px"
            ><div style="height: 4px"></div>
              <v-img

                  class=" img ma-2 img pt-4"

                  src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                  height="350px"
                  width="320"
                  aspect-ratio="1"
              ></v-img>

              <v-card-title>
                Top western road trips
              </v-card-title>

              <v-card-subtitle>
                1,000 miles of wonder
              </v-card-subtitle>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <br>
      <br>
      <br>
      <v-row justify="center" class="ml-0 mr-0 pl-0 pr-0">
        <v-col style="border-radius: 20px" cols="12" md="4">
          <v-hover v-slot="{ hover }">
            <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto neumorphic variation1"
                max-width="335"
                max-height="445"
                style="border-radius: 20px"
            ><div style="height: 4px"></div>
              <v-img

                  class=" img ma-2 img pt-4"

                  src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                  height="350px"
                  width="320"
                  aspect-ratio="1"
              ></v-img>

              <v-card-title>
                Top western road trips
              </v-card-title>

              <v-card-subtitle>
                1,000 miles of wonder
              </v-card-subtitle>
            </v-card>
          </v-hover>
        </v-col>
        <v-col style="border-radius: 20px" cols="12" md="4">
          <v-hover v-slot="{ hover }">
            <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto neumorphic variation1"
                max-width="335"
                max-height="445"
                style="border-radius: 20px"
            ><div style="height: 4px"></div>
              <v-img

                  class=" img ma-2 img pt-4"

                  src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                  height="350px"
                  width="320"
                  aspect-ratio="1"
              ></v-img>

              <v-card-title>
                Top western road trips
              </v-card-title>

              <v-card-subtitle>
                1,000 miles of wonder
              </v-card-subtitle>
            </v-card>
          </v-hover>
        </v-col>
        <v-col style="border-radius: 20px" cols="12" md="4">
          <v-hover v-slot="{ hover }">
            <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto neumorphic variation1"
                max-width="335"
                max-height="445"
                style="border-radius: 20px"
            ><div style="height: 4px"></div>
              <v-img

                  class=" img ma-2 img pt-4"

                  src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                  height="350px"
                  width="320"
                  aspect-ratio="1"
              ></v-img>

              <v-card-title>
                Top western road trips
              </v-card-title>

              <v-card-subtitle>
                1,000 miles of wonder
              </v-card-subtitle>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <br>
    </v-container>
    <landing-page-footer></landing-page-footer>
  </v-app>
</template>
<script>
import LandingPageFooter from "@/components/layouts/LandingPageFooter";
import LandingPageBanner from "@/components/layouts/LandingPageBanner";
import Navbar from "@/components/layouts/Navbar";

export default {
  name: 'About',

  components: {
    LandingPageBanner,
    Navbar,
    LandingPageFooter
  },

  data: () => ({
    transparent: 'rgba(255, 255, 255, 0)'
  }),
  methods:{

  },
  mounted() {
  },
  created() {
    this.$vuetify.rtl = localStorage.getItem("rtl") === "true";
    this.$i18n.locale = localStorage.getItem('locale');
  }
};
</script>
<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>
<style scoped>
.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, .1);
  width: 400px;
  height: 600px;
  border-radius: 40px;

}

.variation1 {
  background: linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.1),
      rgba(255, 255, 255, 0.5)
  );
  padding-bottom: 6px;
}
.img{
  border-radius: 30px;
}
</style>