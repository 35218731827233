<template>
  <v-app>
    <LandingPageBanner></LandingPageBanner>
    <Navbar></Navbar>
    <LnadingPageCards></LnadingPageCards>
    <landing-page-footer></landing-page-footer>
  </v-app>
</template>
<script>
import LandingPageFooter from "@/components/layouts/LandingPageFooter";
import LnadingPageCards from "@/components/layouts/LnadingPageCards";
import LandingPageBanner from "@/components/layouts/LandingPageBanner";
import Navbar from "@/components/layouts/Navbar";

export default {
  name: 'App',

  components: {
    LandingPageBanner,
    Navbar,
    LnadingPageCards,
    LandingPageFooter
  },

  data: () => ({}),
  methods:{

  },
  mounted() {
  },
  created() {
    this.$vuetify.rtl = localStorage.getItem("rtl") === "true";
    this.$i18n.locale = localStorage.getItem('locale');
  }
};
</script>
<style scoped>
.v-select.v-input--dense .v-select__selection--comma {
  margin: 5px 4px 3px 0;
  color: blue!important;
}
p, span, h1, h2, h3, h4, h5, h6 {
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

}

</style>