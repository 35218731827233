
<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="11" sm="6">
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-tabs
                  color="deep-purple accent-4"
                  show-arrows
                  centered
                  hide-slider
                  fixed-tabs
                  class="pad neumorphic ma-0 pl-0 pr-0"
              >
                <v-tab to="/">{{ $t("navbar.services") }}</v-tab>
                <v-tab  to="/projects">{{ $t("navbar.ourProjects") }}</v-tab>
                <v-tab to="/about">{{ $t("navbar.aboutUs") }}</v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Navbar",
  data:()=>({
    fb: 'mdi-web',
  }),
  methods: {
  },
  computed: {
    isRtl() {
      return this.$vuetify.rtl;
    },
  }
}
</script>

<style scoped>
#navigation ul {
  margin-left: 9rem;
  margin-right: 5rem;
}

#navigation ul li {
  list-style-type: none;
  display: inline;
  margin-left: 5rem;
  font-size: 18px;
}

#navigation ul li a {
  text-decoration: none;
}

.pad {
  margin-top: 4rem;
}

.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, 8);
  font-size: 8rem;
  padding: 1.3rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.v-tab {
  text-transform: none;
}
</style>