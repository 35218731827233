<template>
  <v-app>
    <Header></Header>
    <v-container fluid style="padding: 10vh;margin-top: 20vh;margin-bottom: 20vh">
      <v-row>
        <v-col cols="12" md="4" sm="6">
          <v-card
              style="border-radius: 40px"
              class="mx-auto"
              max-width="300"
              height="350"
          >
            <v-img
                :src="img"
                height="250px"
            ></v-img>

            <v-card-title>
              Abdullah
            </v-card-title>

            <v-card-subtitle>
              Senior Civil Eng
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-card>
            <div align="center">
            <v-card-title>Work of Experience</v-card-title>
            <h1  style="color: #c69601;font-size: 10vh;font-weight: bolder">10</h1>
            </div>
          </v-card>
          <br>
          <br>
          <br>
          <h1> <v-icon> mdi-map-marker </v-icon> kuwait city</h1>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-card>
            <div align="center">
              <v-card-title>Year Established</v-card-title>
              <h1  style="color: #c69601;font-size: 10vh;font-weight: bolder">2021</h1>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <br>
      <br>
      <v-row>
        <v-col cols="12" md="1">
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <h1  style="color: #c69601;font-size: 10vh;font-weight: bolder">10</h1>
          <h1  style="font-size: 6vh">Project Done</h1>
        </v-col>
        <v-col cols="12" md="1">
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <h1  style="color: #c69601;font-size: 10vh;font-weight: bolder">21</h1>
          <h1  style="font-size: 6vh">Main Lead</h1>
        </v-col>
        <v-col cols="12" md="1">
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <h1  style="color: #c69601;font-size: 10vh;font-weight: bolder">4</h1>
          <h1  style="font-size: 6vh;">Second Lead</h1>
        </v-col>

      </v-row>
      <br>
      <br>
      <br>
      <br>
      <v-row>
        <v-col cols="12" md="3" sm="6">
          <v-img
          :src="img2">

          </v-img>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-img
              :src="img2">

          </v-img>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-img
              :src="img2">

          </v-img>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-img
              :src="img2">

          </v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="hero">
      <v-col cols="12" md="3" sm="2">
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-img :src="img" style="border-radius: 40px" height="300" width="250"></v-img>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card-title style="color: #c69601">Abdullah</v-card-title>
        <v-card-subtitle class="white--text">Senior Civil Engineer</v-card-subtitle>
        <v-card-text class="white--text">Kuwait City</v-card-text>
        <v-card-text class="white--text"><v-icon color="#c69601"> mdi-phone-in-talk</v-icon>+15151515151</v-card-text>
        <v-card-actions>
          <v-btn width="150" color="#2d2d2d" rounded height="50"><span style="color: #c69601">Contact Agent</span></v-btn>
        </v-card-actions>
      </v-col>
      <v-col cols="12" md="2" sm="2">
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import Header from "@/components/secondlayouts/Header";
export default {
  components:{
    Header
  },
  data: () => ({
    checkbox: false,
    img: require('@/assets/images/civil.png'),
    img2: require('@/assets/images/login image.jpg'),
    staticImg:require('@/assets/images/jois us background.jpg'),
    interiorImg:require('@/assets/images/Axles Agents  Interior Designer.svg')
  }),
}
</script>

<style scoped>
.marg{
  margin: 16px;
}
.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, 8);
  padding: 1.4rem;
}
.hero{
  background-image: url('../assets/images/geo matric background shapes.svg');
  background-size: cover;
  background-color: #2D2D2D;
}
a{
  text-decoration: none;
  font-size: 12px;
  color: white;
}
</style>