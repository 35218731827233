<template>
  <v-app>
  <Header></Header>
  <v-img :src="img"></v-img>
  <v-container>
  <v-row justify="center">
    <v-col cols="12" align="center">
        <v-avatar class="marg neumorphic">
          <img
              src="https://cdn.vuetifyjs.com/images/john.jpg"
              alt="John"
          >
        </v-avatar>
        <v-avatar class="marg">
          <img
              src="https://cdn.vuetifyjs.com/images/john.jpg"
              alt="John"
          >
        </v-avatar>
        <v-avatar class="marg">
          <img
              src="https://cdn.vuetifyjs.com/images/john.jpg"
              alt="John"
          >
        </v-avatar>
    </v-col>
  </v-row>
    <v-row justify="center"> <v-col cols="12" align="center"> <h2 class="pl-4">Interior Design</h2></v-col></v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card height="200" width="450" style="border-radius: 20px">
          <v-row >
            <v-col cols="6" md="6"  style="padding: 0">
              <v-img :src="staticImg" max-height="200"  style="padding: 0;border-radius: 20px 0 0 20px"></v-img>
            </v-col>
            <v-col cols="6" md="6" >
              <router-link to="/profile">
              <v-card-title >Abdullah</v-card-title>
              <v-card-subtitle>Kuwait city</v-card-subtitle>
              <v-card-text style="color: blue">Decoration Company</v-card-text>
              </router-link>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" >
        <div style="height: 50px"></div>
        <v-card height="200" width="450" style="border-radius: 20px">
          <v-row>

            <v-col cols="6" md="6" style="padding: 0">
              <v-img :src="staticImg" max-height="200"  class="rounded" style="padding: 0;border-radius: 20px 0 0 20px"></v-img>
            </v-col>
            <v-col cols="6" md="6">
              <router-link to="/profile">
              <v-card-title >Abdullah</v-card-title>
              <v-card-subtitle>Kuwait city</v-card-subtitle>
              <v-card-text style="color: blue">Decoration Company</v-card-text>
              </router-link>
            </v-col>

          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <br>
    <br>
    <br>
    <v-divider></v-divider>
    <v-card class="pa-4">
    <v-row>
      <v-col cols="12" md="2" sm="6" class="justify-center">
        <v-spacer></v-spacer>
<v-img :src="interiorImg" max-width="100" max-height="100"></v-img>
      </v-col>
        <v-col cols="12" md="7" sm="6">
         <h2> We Are Offering To You To Join Us.<br>
           The People Can Contact With<br>
           You For Interior Design...</h2>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <br>
          <v-btn to="/individual" width="200" class="neumorphic" large rounded><span style="color: #c69601">Join Us</span></v-btn>
        </v-col>
    </v-row>
    </v-card>
  </v-container>
  <LandingPageFooter></LandingPageFooter>
  </v-app>
</template>

<script>
import Header from "@/components/secondlayouts/Header";
import LandingPageFooter from "@/components/layouts/LandingPageFooter";
export default {
  components:{
    Header,
    LandingPageFooter
  },
  data: () => ({
    img: require('@/assets/images/interior.jpg'),
    staticImg:require('@/assets/images/jois us background.jpg'),
    interiorImg:require('@/assets/images/Axles Agents  Interior Designer.svg')
  }),
}
</script>

<style scoped>
.marg{
  margin: 16px;
}
.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, 8);
  padding: 1.4rem;
}
a{
  text-decoration: none;
}
</style>