<template>
  <v-container class="pa-4 text-center">
    <v-row
        class="pt-10"
        align="center"
        justify="center"
    >
      <v-col
          cols="12"
          md="4"
          sm="6"
      >
        <v-hover
            v-slot="{ hover }"
            close-delay="200"
        >
          <v-card
              id="rounded-card"
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class=" mx-auto"
              height="370"
              max-width="280"
          >
            <img :src="icon1" alt="" height="100" width="100">
            <v-card-title class="ma-1 pb-0 font-weight-bold">{{ $t('cards.ourPartners') }}</v-card-title>
            <v-card-text class="ma-1 font-weight-medium text-left text-subtitle-1" :class="{'text-right':isRtl}" >
              <p class="ma-0" >{{ $t('cards.moreThan') }}</p>
              <p class="gold" >{{ $t('cards.vendors') }}</p>
            </v-card-text>
            <br>
            <v-card-actions>
              <v-row justify="center">
                <v-btn to="/our-partners" class="neumorphic variation1" height="60" width="250" rounded>
                  <span>{{ $t('cards.seeMore')}}</span></v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
          cols="12"
          md="4"
          sm="6"
      >
        <v-hover
            v-slot="{ hover }"
            close-delay="200"
        >
          <v-card
              id="rounded-card2"
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class=" mx-auto"
              height="370"
              max-width="280"
          >
            <img :src="icon2" alt="" height="100" width="100">
            <v-card-title class="ma-1 pb-0 font-weight-bold">{{ $t('cards.constructionCalculator') }}</v-card-title>
            <v-card-text class="ma-1 font-weight-medium text-left text-subtitle-1" :class="{'text-right':isRtl}" >
              <p class="ma-0" >{{ $t('cards.helpYou') }}</p>
            </v-card-text>
            <br>
            <br>
            <p v-if="isRtl"></p>
            <v-card-actions>
              <v-row justify="center">
                <v-btn href="https://axlespmg.com/VillityCalculator/Index" class="neumorphic variation1" height="60" width="250" rounded>
                  <span>{{ $t('cards.seeMore')}}</span></v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
          cols="12"
          md="4"
          sm="6"
      >
        <v-hover
            v-slot="{ hover }"
            close-delay="200"
        >
          <v-card
              id="rounded-card1"
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class=" mx-auto"
              height="370"
              max-width="280"
          >
            <img :src="icon3" alt="" height="100" width="100">
            <v-card-title class="ma-1 pb-0 font-weight-bold">{{ $t('cards.ourStore') }}</v-card-title>
            <v-card-text class="ma-1 font-weight-medium text-left text-subtitle-1" :class="{'text-right':isRtl}">
              <p class="ma-0" >{{ $t('cards.constructionProducts') }}</p>
              <p class="gold" >{{ $t('cards.buildingMaterials') }}</p>
            </v-card-text>
            <br>
            <v-card-actions>
              <v-row justify="center">
                <v-btn href="https://axlespmg.com/" class="neumorphic variation1" height="60" width="250" rounded>
                  <span>{{ $t('cards.buyNow')}}</span></v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>

    </v-row>
    <v-row
        class="pt-10 mb-5"

    >
      <v-col
          cols="12"
          md="4"
          sm="6"
      >
        <v-hover
            v-slot="{ hover }"
            close-delay="200"
        >
          <v-card
              id="rounded-card4"
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class=" mx-auto"
              height="370"
              max-width="280"
          >
            <img :src="icon4" alt="" height="100" width="100">
            <v-card-title class="ma-1 pb-0 font-weight-bold">{{ $t('cards.axlesAgents') }}</v-card-title>
            <v-card-text class="ma-1 font-weight-medium text-left text-subtitle-1" :class="{'text-right':isRtl}">
              <p class="ma-0" >{{ $t('cards.moreThan2') }}</p>
              <p class="gold" >{{ $t('cards.salesMan') }}</p>
            </v-card-text>
            <br>
            <v-card-actions>
              <v-row justify="center">
                <v-btn class="neumorphic variation1" height="60" width="250" rounded>
                  <span>{{ $t('cards.seeMore')}}</span></v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
          cols="12"
          md="4"
          sm="6"
      >
        <v-hover
            v-slot="{ hover }"
            close-delay="200"
        >
          <v-card
              id="rounded-card5"
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class=" mx-auto"
              height="370"
              max-width="280"
          >
            <img :src="icon5" alt="" height="100" width="100">
            <v-card-title class="ma-1 pb-0 font-weight-bold">{{ $t('cards.axlesAgents') }}</v-card-title>
            <v-card-text class="ma-1 font-weight-medium text-left text-subtitle-1" :class="{'text-right':isRtl}">
              <p class="ma-0" >{{ $t('cards.constructionCrews') }}</p>
              <p class="gold" >{{ $t('cards.joinUs') }}</p>
            </v-card-text>
            <br>
            <v-card-actions>
              <v-row justify="center">
                <v-btn to="/construction-crews" class="neumorphic variation1" height="60" width="250" rounded>
                  <span>{{ $t('cards.seeMore')}}</span></v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
          cols="12"
          md="4"
          sm="6"
      >
        <v-hover
            v-slot="{ hover }"
            close-delay="200"
        >
          <v-card
              id="rounded-card3"
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class=" mx-auto"
              height="370"
              max-width="280"
          >
            <img :src="icon6" alt="" height="100" width="100">
            <v-card-title class="ma-1 pb-0 font-weight-bold">{{ $t('cards.axlesAgents') }}</v-card-title>
            <v-card-text class="ma-1 font-weight-medium text-left text-subtitle-1" :class="{'text-right':isRtl}">
              <p class="ma-0" >{{ $t('cards.moreAgent') }}</p>
              <p class="gold" >{{ $t('cards.interiorDesigner') }}</p>
            </v-card-text>
            <br>
            <v-card-actions>
              <v-row justify="center">
                <v-btn to="/interior-design" class="neumorphic variation1" height="60" width="250" rounded>
                  <span>{{ $t('cards.seeMore')}}</span></v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LnadingPageCards",
  data: () => ({
    icon1: require('@/assets/images/Our Store.svg'),
    icon2: require('@/assets/images/Construction Calculator.svg'),
    icon3: require('@/assets/images/Our Partners.svg'),
    icon6: require('@/assets/images/Axles Agents  Interior Designer.svg'),
    icon5: require('@/assets/images/Axles Agents  Construction Crews.svg'),
    icon4: require('@/assets/images/Axles Agents  sales man.svg'),

  }),
  methods: {}, computed: {
    isRtl() {
      return this.$vuetify.rtl;
    },
  }
}
</script>

<style scoped>
.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, .1);
  width: 200px;
  height: 200px;
  border-radius: 40px;

}

.variation1 {
  background: linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.1),
      rgba(255, 255, 255, 0.5)
  );
  padding-bottom: 6px;
}

span {
  color: darkblue;
  font-weight: bold;
  font-size: 18px;
}

img {
  padding-top: 20px;
}

.gold {
  color: #c69601;
}

p {
  font-size: 14px;
}

#rounded-card, #rounded-card1, #rounded-card2, #rounded-card3, #rounded-card4, #rounded-card5 {
  border-radius: 25px;
}

.is-rtl {
  direction: rtl !important;
}
</style>