<template>
  <v-app>
  <Header></Header>
  <v-img :src="img"></v-img>
  <v-container>
  <v-row justify="center">
    <v-col cols="12" align="center">
        <v-avatar class="marg neumorphic">
          <img
              src="https://cdn.vuetifyjs.com/images/john.jpg"
              alt="John"
          >
        </v-avatar>
        <v-avatar class="marg">
          <img
              src="https://cdn.vuetifyjs.com/images/john.jpg"
              alt="John"
          >
        </v-avatar>
        <v-avatar class="marg">
          <img
              src="https://cdn.vuetifyjs.com/images/john.jpg"
              alt="John"
          >
        </v-avatar>
    </v-col>
  </v-row>
    <v-row justify="center"> <v-col cols="12" align="center"> <h2 class="pl-4">Our Partners</h2></v-col></v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card height="200" width="450" style="border-radius: 20px">
          <v-row >
            <v-col cols="6" md="6"  style="padding: 0">
              <v-img :src="staticImg" max-height="200"  style="padding: 0;border-radius: 20px 0 0 20px"></v-img>
            </v-col>
            <v-col cols="6" md="6" >
              <v-card-title >Abdullah</v-card-title>
              <v-card-subtitle>Kuwait city</v-card-subtitle>
              <v-card-text style="color: blue">Decoration Company</v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" >
        <div style="height: 50px"></div>
        <v-card height="200" width="450" style="border-radius: 20px">
          <v-row>
            <v-col cols="6" md="6" style="padding: 0">
              <v-img :src="staticImg" max-height="200"  class="rounded" style="padding: 0;border-radius: 20px 0 0 20px"></v-img>
            </v-col>
            <v-col cols="6" md="6">
              <v-card-title >Abdullah</v-card-title>
              <v-card-subtitle>Kuwait city</v-card-subtitle>
              <v-card-text style="color: blue">Decoration Company</v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <LandingPageFooter></LandingPageFooter>
  </v-app>
</template>

<script>
import Header from "@/components/secondlayouts/Header";
import LandingPageFooter from "@/components/layouts/LandingPageFooter";
export default {
  components:{
    Header,
    LandingPageFooter
  },
  data: () => ({
    img: require('@/assets/images/Rectangle 36.jpg'),
    staticImg:require('@/assets/images/join us background 2.jpg')
  }),
}
</script>

<style scoped>
.marg{
  margin: 16px;
}

</style>