import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Projects from "@/views/Projects";
import OurPartners from "@/views/OurPartners";
import InteriorDesign from "@/views/InteriorDesign";
import ConstructionCrews from "@/views/ConstructionCrews";
import Individual from "@/views/Individual";
import Business from "@/views/Business";
import Login from "@/views/Register";
import Register from "@/views/Login";
import Profile from "@/views/Profile";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects
  },
  {
    path: '/our-partners',
    name: 'ourPartners',
    component: OurPartners
  },
  {
    path: '/interior-design',
    name: 'interiorDesign',
    component: InteriorDesign
  },
  {
    path: '/construction-crews',
    name: 'constructionCrews',
    component: ConstructionCrews
  },
  {
    path: '/individual',
    name: 'individual',
    component: Individual
  },
  {
    path: '/business',
    name: 'business',
    component: Business
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
