var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 text-center"},[_c('v-row',{staticClass:"pt-10",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:" mx-auto",class:{ 'on-hover': hover },attrs:{"id":"rounded-card","elevation":hover ? 16 : 2,"height":"370","max-width":"280"}},[_c('img',{attrs:{"src":_vm.icon1,"alt":"","height":"100","width":"100"}}),_c('v-card-title',{staticClass:"ma-1 pb-0 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('cards.ourPartners')))]),_c('v-card-text',{staticClass:"ma-1 font-weight-medium text-left text-subtitle-1",class:{'text-right':_vm.isRtl}},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.$t('cards.moreThan')))]),_c('p',{staticClass:"gold"},[_vm._v(_vm._s(_vm.$t('cards.vendors')))])]),_c('br'),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"neumorphic variation1",attrs:{"to":"/our-partners","height":"60","width":"250","rounded":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('cards.seeMore')))])])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:" mx-auto",class:{ 'on-hover': hover },attrs:{"id":"rounded-card2","elevation":hover ? 16 : 2,"height":"370","max-width":"280"}},[_c('img',{attrs:{"src":_vm.icon2,"alt":"","height":"100","width":"100"}}),_c('v-card-title',{staticClass:"ma-1 pb-0 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('cards.constructionCalculator')))]),_c('v-card-text',{staticClass:"ma-1 font-weight-medium text-left text-subtitle-1",class:{'text-right':_vm.isRtl}},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.$t('cards.helpYou')))])]),_c('br'),_c('br'),(_vm.isRtl)?_c('p'):_vm._e(),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"neumorphic variation1",attrs:{"href":"https://axlespmg.com/VillityCalculator/Index","height":"60","width":"250","rounded":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('cards.seeMore')))])])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:" mx-auto",class:{ 'on-hover': hover },attrs:{"id":"rounded-card1","elevation":hover ? 16 : 2,"height":"370","max-width":"280"}},[_c('img',{attrs:{"src":_vm.icon3,"alt":"","height":"100","width":"100"}}),_c('v-card-title',{staticClass:"ma-1 pb-0 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('cards.ourStore')))]),_c('v-card-text',{staticClass:"ma-1 font-weight-medium text-left text-subtitle-1",class:{'text-right':_vm.isRtl}},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.$t('cards.constructionProducts')))]),_c('p',{staticClass:"gold"},[_vm._v(_vm._s(_vm.$t('cards.buildingMaterials')))])]),_c('br'),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"neumorphic variation1",attrs:{"href":"https://axlespmg.com/","height":"60","width":"250","rounded":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('cards.buyNow')))])])],1)],1)],1)]}}])})],1)],1),_c('v-row',{staticClass:"pt-10 mb-5"},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:" mx-auto",class:{ 'on-hover': hover },attrs:{"id":"rounded-card4","elevation":hover ? 16 : 2,"height":"370","max-width":"280"}},[_c('img',{attrs:{"src":_vm.icon4,"alt":"","height":"100","width":"100"}}),_c('v-card-title',{staticClass:"ma-1 pb-0 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('cards.axlesAgents')))]),_c('v-card-text',{staticClass:"ma-1 font-weight-medium text-left text-subtitle-1",class:{'text-right':_vm.isRtl}},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.$t('cards.moreThan2')))]),_c('p',{staticClass:"gold"},[_vm._v(_vm._s(_vm.$t('cards.salesMan')))])]),_c('br'),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"neumorphic variation1",attrs:{"height":"60","width":"250","rounded":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('cards.seeMore')))])])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:" mx-auto",class:{ 'on-hover': hover },attrs:{"id":"rounded-card5","elevation":hover ? 16 : 2,"height":"370","max-width":"280"}},[_c('img',{attrs:{"src":_vm.icon5,"alt":"","height":"100","width":"100"}}),_c('v-card-title',{staticClass:"ma-1 pb-0 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('cards.axlesAgents')))]),_c('v-card-text',{staticClass:"ma-1 font-weight-medium text-left text-subtitle-1",class:{'text-right':_vm.isRtl}},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.$t('cards.constructionCrews')))]),_c('p',{staticClass:"gold"},[_vm._v(_vm._s(_vm.$t('cards.joinUs')))])]),_c('br'),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"neumorphic variation1",attrs:{"to":"/construction-crews","height":"60","width":"250","rounded":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('cards.seeMore')))])])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:" mx-auto",class:{ 'on-hover': hover },attrs:{"id":"rounded-card3","elevation":hover ? 16 : 2,"height":"370","max-width":"280"}},[_c('img',{attrs:{"src":_vm.icon6,"alt":"","height":"100","width":"100"}}),_c('v-card-title',{staticClass:"ma-1 pb-0 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('cards.axlesAgents')))]),_c('v-card-text',{staticClass:"ma-1 font-weight-medium text-left text-subtitle-1",class:{'text-right':_vm.isRtl}},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.$t('cards.moreAgent')))]),_c('p',{staticClass:"gold"},[_vm._v(_vm._s(_vm.$t('cards.interiorDesigner')))])]),_c('br'),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"neumorphic variation1",attrs:{"to":"/interior-design","height":"60","width":"250","rounded":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('cards.seeMore')))])])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }