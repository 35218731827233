<template>
  <v-footer
      color="#2D2D2D"
      tile
      class="ma-0 pa-0"
  >
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" md="6" sm="6" class="pa-0 ma-0">
          <v-card class="pa-0 ma-0">
            <v-img :src="footerImg"></v-img>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" sm="6" class="text-center">
          <v-row justify="center">
            <v-card-title>
              <v-img :src="logo"
                     max-height="120"
                     max-width="220"
              ></v-img>
            </v-card-title>
          </v-row>
          <v-row justify="center">
            <v-card-text class="text-md-h6 font-weight-thin  white--text">
              {{ $t('banner.introduction')}}
            </v-card-text>
            <v-row>
              <v-col cols="12" md="7" sm="6">
                <v-btn
                    class=" white--text"
                    icon
                >
                  <v-icon size="24px" style="color: #c69601">
                    {{ fb }}
                  </v-icon>
                </v-btn>
                <v-btn
                    class="mx-4 white--text"
                    icon
                >
                  <v-icon size="24px" style="color: #c69601">
                    {{ fb1 }}
                  </v-icon>
                </v-btn>
              </v-col>
          </v-row>
            <v-container fluid>
                <v-row justify="center">
                  <v-col cols="12" md="7" sm="6">
                  <v-text-field filled full-width outlined :placeholder=" $t('footer.name')"></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center" class="mt-0">
                  <v-col cols="12" md="7" sm="6">
                  <v-text-field filled full-width :placeholder=" $t('footer.email')" outlined></v-text-field>
                  </v-col>
                </v-row>
              <v-row justify="center">
                <v-col cols="12" md="7" sm="6">
                  <v-btn outlined height="60"   ><span>{{ $t('footer.getIn')}}</span><v-icon v-if="!isRtl" size="30px"  style="color: #c69601">
                    {{ fb3 }}
                  </v-icon>
                    <v-icon v-if="isRtl" size="30px"  style="color: #c69601">
                      {{ fb4 }}
                    </v-icon></v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "LandingPageFooter",
  data: () => ({
    footerImg: require('@/assets/images/imagedownside.jpg'),
    logo: require('@/assets/images/logo white color.svg'),

    fb: 'mdi-web',
    fb1: 'mdi-phone-in-talk',
    fb3:'mdi-arrow-right',
    fb4:'mdi-arrow-left'

  }),
  computed: {
    isRtl() {
      return this.$vuetify.rtl;
    },
  }
}
</script>

<style scoped>
span{
  color: #c69601;
  text-transform: none;
  font-weight: bold;
}
</style>