<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-img :src="logo" max-height="60" max-width="180"></v-img>
        <v-card-title>{{$t('banner.introduction')}}
        </v-card-title>
        <v-card-actions>
          <v-container>
            <v-row >
              <v-col cols="12" md="3" sm="12" class="pt-10  ">
              <v-menu
                  offset-y
                  left
              >
                <template v-slot:activator="{ attrs, on }">
                  <span>
                    <br>
                    <v-icon
                        color="#c69601"
                        v-bind="attrs"
                        v-on="on"
                    >{{langLogo}}</v-icon>
                    <span v-show="isRtl"> عربي</span>
                    <span v-show="!isRtl"> English</span>
                    </span>
                </template>

                <v-list>
                  <v-list-item
                  >
                    <a href="#" @click="setLang('ar')">عربي</a>
                  </v-list-item>
                  <v-list-item
                  >
                    <a href="#" @click="setLang('en')">English</a>
                  </v-list-item>
                </v-list>
              </v-menu>
              </v-col>
              <v-col cols="12" md="1" sm="12">
<!--          <v-btn height="60" width="250" class="neumorphic variation1 mt-12" href="https://axlespmg.com/customer/info" target="_blank"><span>{{$t('banner.login')}} </span></v-btn>-->
              </v-col>
              <v-col cols="6">
                <v-select
                    color="primary"
                    v-model="e1"
                    :items="states"
                    class="neumorphic1 pt-3 mt-12"
                    rounded
                    menu-props="auto"
                    dense
                    single-line
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-col>
      <v-col cols="12" md="8" sm="12" class="pa-0 ma-0">
        <v-container class="mt-0 pt-0">
        <img :src="img" width="890vh" height="340vh" style="margin: 0 ;padding: 0">
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LandingPageBanner",
  data: () => ({
    langLogo:'mdi-web',
    logo: require('@/assets/images/logo.svg'),
    img: require('@/assets/images/imageupside.jpg'),
    e1: 'Kuwait',
    states: [
      'Kuwait', 'Oman', 'KSA', 'Qatar','UAE','Bahrain'
    ],
  }),
  methods: {
    setLang(lang)
    {
      this.$i18n.locale = lang;
      this.$vuetify.rtl = lang == "ar" ? true : false;
      localStorage.setItem("locale", lang);
      localStorage.setItem("rtl", lang == "ar" ? true : false);
    }
  },
  computed: {
    isRtl() {
      return this.$vuetify.rtl;
    },
  }
}
</script>

<style scoped>
.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, .1);
  width: 200px;
  height: 200px;
  border-radius: 40px;
  overflow: hidden;
  padding: 1.3rem;
  display: flex;

}
.neumorphic1 {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, .1);
  border-radius: 40px;
  overflow: hidden;
  display: flex;
  color: blue !important;

}

.variation1 {
  background: linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.1),
      rgba(255, 255, 255, 0.5)
  );
}

span {
  text-transform: none;
  color: blue;
  font-size: 18px;
}
@media only screen and (max-width: 600px) {
  img {
    height: auto;
    width: 100%;
  }
}
a{
  text-decoration: none;
}
</style>