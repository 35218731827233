<template>
  <v-app>
  <Header></Header>
  <v-img :src="img"></v-img>
  <v-container>
  <v-row justify="center">
    <v-col cols="12" align="center">
        <v-avatar class="marg neumorphic">
          <img
              src="https://cdn.vuetifyjs.com/images/john.jpg"
              alt="John"
          >
        </v-avatar>
        <v-avatar class="marg">
          <img
              src="https://cdn.vuetifyjs.com/images/john.jpg"
              alt="John"
          >
        </v-avatar>
        <v-avatar class="marg">
          <img
              src="https://cdn.vuetifyjs.com/images/john.jpg"
              alt="John"
          >
        </v-avatar>
    </v-col>
  </v-row>
    <v-row justify="center"> <v-col cols="12" align="center"> <h2 class="pl-4">Construction Crews</h2></v-col></v-row>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-text-field
            label="Search"
            outlined
            placeholder="Search Construction Crews"
            append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
            :items="items"
            label="All Crews"
            outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card height="200" width="450" style="border-radius: 20px">
          <v-row >
            <v-col cols="6" md="6"  style="padding: 0">
              <v-img :src="staticImg" max-height="200"  style="padding: 0;border-radius: 20px 0 0 20px"></v-img>
            </v-col>
            <v-col cols="6" md="6" >
              <v-card-title class="pt-0">Abdullah</v-card-title>
              <v-card-text style="color: blue">Painter</v-card-text>
              <p class="pl-4">
                <v-icon size="15px" style="color: #c69601">
                  mdi-phone-in-talk
                </v-icon>
                +21644515
              </p>
              <v-card-actions class="mx-2 pb-3">
                <v-btn class="neumorphic " rounded width="150">
                  <v-icon color="#c69601">
                    mdi-whatsapp
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" >
        <div style="height: 50px"></div>
        <v-card height="200" width="450" style="border-radius: 20px">
          <v-row>
            <v-col cols="6" md="6" style="padding: 0">
              <v-img :src="staticImg" max-height="200"  class="rounded" style="padding: 0;border-radius: 20px 0 0 20px"></v-img>
            </v-col>
            <v-col cols="6" md="6" >
              <v-card-title class="pt-0">Abdullah</v-card-title>
              <v-card-text style="color: blue">Painter</v-card-text>
              <p class="pl-4">
                <v-icon size="15px" style="color: #c69601">
                  mdi-phone-in-talk
                </v-icon>
                +21644515
              </p>
              <v-card-actions class="mx-2">
                <v-btn class="neumorphic " rounded width="150">
                  <v-icon color="#c69601">
                    mdi-whatsapp
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <br>
    <br>
    <br>
    <v-divider></v-divider>
    <v-card class="pa-4">
    <v-row>
      <v-col cols="12" md="2" sm="6" class="justify-center">
        <v-spacer></v-spacer>
<v-img :src="interiorImg" max-width="100" max-height="100"></v-img>
      </v-col>
        <v-col cols="12" md="7" sm="6">
         <h2> We Are Offering To You To Join Us.<br>
           The People Can Contact With<br>
           You For Interior Design...</h2>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <br>
          <v-btn to="/individual" width="200" class="neumorphic" large rounded><span style="color: #c69601">Join Us</span></v-btn>
        </v-col>
    </v-row>
    </v-card>
  </v-container>
  <LandingPageFooter></LandingPageFooter>
  </v-app>
</template>

<script>
import Header from "@/components/secondlayouts/Header";
import LandingPageFooter from "@/components/layouts/LandingPageFooter";
export default {
  components:{
    Header,
    LandingPageFooter
  },
  data: () => ({
    items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
    img: require('@/assets/images/banner 3.jpg'),
    staticImg:require('@/assets/images/jois us background.jpg'),
    interiorImg:require('@/assets/images/Axles Agents  Construction Crews.svg')
  }),
}
</script>

<style scoped>
.marg{
  margin: 16px;
}
.neumorphic {
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4),
  -12px -12px 24px 0 rgba(255, 255, 255, 8);
  padding: 1.4rem;
}
</style>